import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUsers } from './reducers/usersSlice';
import { setMyProfile } from './reducers/profileSlice';
import { setRoles } from './reducers/rolesSlice';
import { setShows } from './reducers/showsSlice';
import toast from 'react-hot-toast';
import { setSchedule } from './reducers/scheduleSlice';

export default function RxWrapper({ children, api }) {
    const reduxDispatch = useDispatch();

    useEffect(() => {
        //Get Users & Roles
        (async () => {
            const loadProfileAndUsers = async () => {
                const profile = await api.getMyProfile().catch(error => {
                    console.warn(error);
                    toast.error(error.message);
                });
                if (profile) reduxDispatch(setMyProfile({ profile }));

                if (profile.Role !== 3) { // If not a presenter, get all users, shows and schedule
                    const loadUsers = async () => {
                        const users = await api.getAllUsers().catch(error => {
                            console.warn(error);
                            toast.error(error.message);
                        });
                        if (users?.length > 0) reduxDispatch(setUsers({ users }));
                    };

                    const loadShows = async () => {
                        const shows = await api.getAllShows().catch(error => {
                            console.warn(error);
                            toast.error(error.message);
                        });
                        if (shows?.length > 0) reduxDispatch(setShows({ shows }));
                    };

                    const loadSchedule = async () => {
                        const schedule = await api.getFullSchedule().catch(error => {
                            console.warn(error);
                            toast.error(error.message);
                        });
                        if (schedule?.length > 0) reduxDispatch(setSchedule({ schedule }));
                    };

                    await Promise.all([loadUsers(), loadShows(), loadSchedule()]);
                } else {
                    const loadShows = async () => {
                        const shows = await api.getMyShows().catch(error => {
                            console.warn(error);
                            toast.error(error.message);
                        });
                        if (shows?.length > 0) reduxDispatch(setShows({ shows }));
                    };
                    await loadShows();
                }
            };

            const loadRoles = async () => {
                const roles = await api.getAllRoles().catch(error => {
                    console.warn(error);
                    toast.error(error.message);
                });
                if (roles?.length > 0) reduxDispatch(setRoles({ roles }));
            };

            await Promise.all([loadProfileAndUsers(), loadRoles()]);
        })();
    }, []);
    return (
        <>
            {children}
        </>
    );
}