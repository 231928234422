export default class NerveAPI {

    HOST = process.env.NODE_ENV === 'production' ? 'https://api.staff.nervemedia.org.uk/' : 'http://localhost:4000/';
    //HOST = 'https://api.staff.nervemedia.org.uk/';
    TOKEN = '';

    msHeaders({ method = 'GET', body = null } = '') {
        const headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.TOKEN });
        return { headers: headers, method: method, body: body };
    }

    async auth() {
        const res = await (fetch(this.HOST + 'auth', this.msHeaders()).catch(console.warn));
        if (res?.ok) return true;
        else return false;
    }

    async getMyProfile() {
        const res = await (fetch(this.HOST + 'users/me', this.msHeaders()).catch(console.warn));
        if (res.ok) {
            const body = await res.json();
            if (body.userFound === true) return body.user;
            throw new Error('ProfileNotFound');
        }
        throw new Error('UnableToRetrieveProfile');
    }

    async getUserByID(uid) {
        const res = await (fetch(this.HOST + 'users/' + uid, this.msHeaders()).catch(console.warn));
        if (res.ok) {
            const body = await res.json();
            if (body.userFound === true) return body.user;
            throw new Error('UserNotFound');
        }
        throw new Error('UnableToRetrieveUsers');
    }

    async getAllUsers() {
        const res = await (fetch(this.HOST + 'users/', this.msHeaders()).catch(console.warn));
        if (res.ok) {
            const body = await res.json();
            if (body.success === true) return body.users;
            throw new Error('NoUsersFound');
        }
        throw new Error('UnableToRetrieveUsers');
    }

    async getAllRoles() {
        const res = await (fetch(this.HOST + 'users/roles/', this.msHeaders()).catch(console.warn));
        if (res.ok) {
            const body = await res.json();
            if (body.success === true) return body.roles;
            throw new Error('NoRolesFound');
        }
        throw new Error('UnableToRetrieveUserRoles');
    }

    async createUser(uid, rid, name, email) {
        const res = await (fetch(this.HOST + 'users/', this.msHeaders({ method: 'PUT', body: JSON.stringify({ uid: uid, rid: rid, name, email }) })).catch(console.warn));
        if (res.ok) {
            const body = await res.json();
            if (body.success === true) return true;
            else throw new Error(body.error);
        }
        throw new Error('UnableToAddUser');
    }

    async archiveUser(uid) {
        const res = await (fetch(this.HOST + 'users/' + uid + '/archive', this.msHeaders({ method: 'PATCH' })).catch(console.warn));
        try {
            const body = await res.json();
            if (body.success === true) return true;
            throw new Error(body.error);
        } catch (error) {
            console.error(error);
            throw new Error(error.message);
        }
    }

    async restoreUser(uid) {
        const res = await (fetch(this.HOST + 'users/' + uid + '/restore', this.msHeaders({ method: 'PATCH' })).catch(console.warn));
        try {
            const body = await res.json();
            if (body.success === true) return true;
            throw new Error(body.error);
        } catch (error) {
            console.error(error);
            throw new Error(error.message);
        }
    }

    async deleteUser(uid) {
        const res = await (fetch(this.HOST + 'users/' + uid, this.msHeaders({ method: 'DELETE' })).catch(console.warn));
        if (res.ok) {
            const body = await res.json();
            if (body.success === true) return true;
            throw new Error('UnableToDeleteUser');
        }
        throw new Error('UnableToDeleteUser');
    }

    async updateUserDetails(uid, rid) {
        const res = await (fetch(this.HOST + 'users/' + uid, this.msHeaders(
            {
                method: 'POST',
                body: JSON.stringify({
                    rid: rid
                })
            }
        )).catch(console.warn));
        if (res.ok) {
            const body = await res.json();
            if (body.success === true) return true;
            throw new Error('UnableToUpdateUser');
        }
        throw new Error('UnableToUpdateUser');
    }
        
    async getUserLogs(uid) {
        const res = await (fetch(this.HOST + 'users/' + uid + '/logs', this.msHeaders()).catch(console.warn));
        try {
            const body = await res.json();
            if (body.success === true) return body.logs;
            throw new Error(body.error);
        } catch (error) {
            console.error(error);
            throw new Error(error.message);
        }
    }

    async getAllShows() {
        const res = await (fetch(this.HOST + 'shows/', this.msHeaders()).catch(console.warn));
        if (res.ok) {
            const body = await res.json();
            if (body.success === true) return body.shows;
            throw new Error('NoShowsFound');
        }
        throw new Error('UnableToRetrieveShows');
    }

    async getMyShows() {
        const res = await (fetch(this.HOST + 'shows/my', this.msHeaders()).catch(console.warn));
        if (res.ok) {
            const body = await res.json();
            if (body.success === true) return body.shows;
            throw new Error('NoPersonalShowsFound');
        }
        throw new Error('UnableToRetrievePersonalShows');
    }

    async getShowByID(id) {
        const res = await (fetch(this.HOST + 'shows/' + id, this.msHeaders()).catch(console.warn));
        if (res.ok) {
            const body = await res.json();
            if (body.success === true) return body.show;
            throw new Error('ShowNotFound');
        }
        throw new Error('UnableToRetrieveShow');
    }

    async createShow(name, uid) {
        const res = await (fetch(this.HOST + 'shows/', this.msHeaders({ method: 'PUT', body: JSON.stringify({ name: name, uid: uid }) })).catch(console.warn));
        if (res.ok) {
            const body = await res.json();
            if (body.success === true) return body.ShowID;
            throw new Error('UnableToAddShow');
        }
        throw new Error('UnableToAddShow');
    }

    async addHostToShow(sid, uid) {
        const res = await (fetch(this.HOST + `shows/${sid}/host`, this.msHeaders({ method: 'PUT', body: JSON.stringify({ UserID: uid }) })).catch(console.warn));
        try {
            const body = await res.json();
            if (body.success === true) return true;
            throw new Error(body.error);
        } catch (error) {
            console.error(error);
            throw new Error(error.message);
        }
    }

    async removeHostFromShow(sid, uid) {
        const res = await (fetch(this.HOST + `shows/${sid}/host`, this.msHeaders({ method: 'DELETE', body: JSON.stringify({ UserID: uid }) })).catch(console.warn));
        try {
            const body = await res.json();
            if (body.success === true) return true;
            throw new Error(body.error);
        } catch (error) {
            console.error(error);
            throw new Error(error.message);
        }
    }

    async archiveShow(sid) {
        const res = await (fetch(this.HOST + 'shows/' + sid + '/archive', this.msHeaders({ method: 'PATCH' })).catch(console.warn));
        try {
            const body = await res.json();
            if (body.success === true) return true;
            throw new Error(body.error);
        } catch (error) {
            console.error(error);
            throw new Error(error.message);
        }
    }

    async restoreShow(sid) {
        const res = await (fetch(this.HOST + 'shows/' + sid + '/restore', this.msHeaders({ method: 'PATCH' })).catch(console.warn));
        try {
            const body = await res.json();
            if (body.success === true) return true;
            throw new Error(body.error);
        } catch (error) {
            console.error(error);
            throw new Error(error.message);
        }
    }

    async deleteShow(sid) {
        const res = await (fetch(this.HOST + 'shows/' + sid, this.msHeaders({ method: 'DELETE' })).catch(console.warn));
        try {
            const body = await res.json();
            if (body.success === true) return true;
            throw new Error(body.error);
        } catch (error) {
            console.error(error);
            throw new Error(error.message);
        }
    }

    async updateShowDetails(sid, sName, sHost, sDesc, sRecord, sInstagram) {
        const res = await (fetch(this.HOST + 'shows/' + sid, this.msHeaders(
            {
                method: 'POST',
                body: JSON.stringify({
                    name: sName,
                    host: sHost,
                    desc: sDesc,
                    record: sRecord,
                    instagram: sInstagram
                })
            }
        )).catch(console.warn));
        if (res.ok) {
            const body = await res.json();
            if (body.success === true) return true;
            throw new Error('UnableToUpdateShow');
        }
        throw new Error('UnableToUpdateShow');
    }

    async getShowRecordings(sid) {
        const res = await (fetch(this.HOST + 'shows/' + sid + '/recordings', this.msHeaders()).catch(console.warn));
        try {
            if (res.ok) {
                const body = await res.json();
                if (body.success === true) return body.recordings;
                throw new Error(body.error);
            }
        } catch (error) {
            console.error(error);
            throw new Error(error.message);
        }
    }

    async archiveRecording(sid, rid) {
        const res = await (fetch(this.HOST + 'shows/' + sid + '/recordings/' + rid + '/archive', this.msHeaders({ method: 'PATCH' })).catch(console.warn));
        try {
            const body = await res.json();
            if (body.success === true) return true;
            throw new Error(body.error);
        } catch (error) {
            console.error(error);
            throw new Error(error.message);
        }
    }

    async restoreRecording(sid, rid) {
        const res = await (fetch(this.HOST + 'shows/' + sid + '/recordings/' + rid + '/restore', this.msHeaders({ method: 'PATCH' })).catch(console.warn));
        try {
            const body = await res.json();
            if (body.success === true) return true;
            throw new Error(body.error);
        } catch (error) {
            console.error(error);
            throw new Error(error.message);
        }
    }

    async moveRecording(sid, rid, nid) {
        const res = await (fetch(this.HOST + 'shows/' + sid + '/recordings/' + rid + '/move/' + nid, this.msHeaders({ method: 'PATCH' })).catch(console.warn));
        try {
            const body = await res.json();
            if (body.success === true) return true;
            throw new Error(body.error);
        } catch (error) {
            console.error(error);
            throw new Error(error.message);
        }
    }

    async getShowLogs(sid) {
        const res = await (fetch(this.HOST + 'shows/' + sid + '/logs', this.msHeaders()).catch(console.warn));
        try {
            const body = await res.json();
            if (body.success === true) return body.logs;
            throw new Error(body.error);
        } catch (error) {
            console.error(error);
            throw new Error(error.message);
        }
    }

    async getFullSchedule() {
        const res = await (fetch(this.HOST + 'schedule/', this.msHeaders()).catch(console.warn));
        if (res.ok) {
            const body = await res.json();
            if (body.success === true) return body.schedule;
            throw new Error('UnableToRetrieveSchedule');
        }
        throw new Error('UnableToRetrieveSchedule');
    }

    async createScheduleSlot(slotDay, slotShow, slotTimeStart, slotTimeEnd) {
        const res = await (fetch(this.HOST + 'schedule/', this.msHeaders(
            {
                method: 'PUT',
                body: JSON.stringify({
                    day: slotDay,
                    show: slotShow,
                    timeStart: slotTimeStart,
                    timeEnd: slotTimeEnd
                })
            }
        )).catch(console.warn));
        if (res.ok) {
            const body = await res.json();
            if (body.success === true) return body.SlotID;
            throw new Error(body.error);
        }
        throw new Error('UnableToCreateScheduleSlot');
    }

    async updateScheduleSlot(slotID, slotDay, slotShow, slotTimeStart, slotTimeEnd) {
        const res = await (fetch(this.HOST + 'schedule/' + slotID, this.msHeaders(
            {
                method: 'POST',
                body: JSON.stringify({
                    day: slotDay,
                    show: slotShow,
                    timeStart: slotTimeStart,
                    timeEnd: slotTimeEnd
                })
            }
        )).catch(console.warn));
        if (res.ok) {
            const body = await res.json();
            if (body.success === true) return true;
            throw new Error(body.error);
        }
        throw new Error('UnableToUpdateScheduleSlot');
    }

    async deleteScheduleSlot(sid) {
        const res = await (fetch(this.HOST + 'schedule/' + sid, this.msHeaders({ method: 'DELETE' })).catch(console.warn));
        if (res.ok) {
            const body = await res.json();
            if (body.success === true) return true;
            throw new Error('UnableToDeleteSlot');
        }
        throw new Error('UnableToDeleteSlot');
    }

}