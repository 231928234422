import React, { useEffect, useState } from 'react';
import { Accordion, Card, Col, Container, FormControl, FormLabel, FormSelect, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import './user.css';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { updateUser } from '../utils/reducers/usersSlice';

const pendingChangesTitle = 'Changes are pending to be saved...';

export default function User({ api }) {
    const { id: userID } = useParams();
    
    const users = useSelector(state => state.users.users);
    const profile = useSelector(state => state.profile.profile);
    const [logs, setLogs] = useState([]);
    const reduxDispatch = useDispatch();
    const navigate = useNavigate();

    const userDetails = users.find(u => u?.UserID === userID);
    const [tempUserDetails, setTempUserDetails] = useState(null);

    logs;
    useEffect(() => {
        document.title = process.env.REACT_APP_PAGE_TITLE+'Edit User';
        setTempUserDetails(userDetails);

        // Get user logs
        setLogs(null);
        if (profile?.Role !== 3) api.getUserLogs(userID).then(logs => {
            setLogs(logs);
        }).catch(error => {
            toast.error(error.message);
            console.error(error);
        });
    }, [userDetails?.UserID !== userID, userDetails?.UserID !== tempUserDetails?.UserID]);

    if (!userDetails || !tempUserDetails) return <div />;

    const hasChanged = (key) => {
        if (key === undefined) {
            let changeNoticed = false;
            Object.keys(userDetails).forEach((oKey) => {
                if (changeNoticed) return;
                if ((userDetails && tempUserDetails) && userDetails[oKey] !== tempUserDetails[oKey]) changeNoticed = true;
            });
            return changeNoticed;
        }
        return userDetails[key] !== tempUserDetails[key];
    };

    const userArchived = userDetails?.Archived === 1;

    const updateUserDetails = () => {
        if (userArchived) return toast.error('Cannot update archived users.');
        api.updateUserDetails(tempUserDetails?.UserID, tempUserDetails.Role).then(result => {
            if (result) {
                toast.success('User Updated successfully.');
                reduxDispatch(updateUser({
                    id: tempUserDetails.UserID,
                    Role: tempUserDetails.Role,
                }));
                if (profile.Role !== 3) navigate('/users/list');
            }
            else toast.error('Show details were not updated.');
        }).catch(error => {
            console.warn(error);
            toast.error('Could not update: ' + error.message);
        });
    };
    
    return (
        <Container className="my-4" fluid="md">
            <h1>User: {userDetails?.Name}</h1>
            <hr />
            {userDetails !== undefined &&
                <>
                    <Card>
                        <Card.Header>
                            <h4 className="d-inline-block">Account Details</h4>
                            {hasChanged() && <button className="btn btn-warning float-end" onClick={updateUserDetails}>Save</button>}
                        </Card.Header>
                        <Card.Body>
                            <Row id="userDetails" className="text-center col-lg-9 mx-lg-auto">
                                <Col md={12} lg={6}>
                                    <Row>
                                        <Col xs={4}><FormLabel>Name:</FormLabel></Col>
                                        <Col xs={8}><FormControl className="d-inline-block" readOnly disabled value={userDetails?.Name || 'loading...'} /></Col>
                                    </Row>
                                </Col>
                                <Col md={12} lg={6}>
                                    <Row>
                                        <Col xs={4}><FormLabel>Email:</FormLabel></Col>
                                        <Col xs={8}><FormControl className="d-inline-block" readOnly disabled value={userDetails?.Email || 'loading...'} /></Col>
                                    </Row>
                                </Col>
                                <Col md={12} lg={6}>
                                    <Row>
                                        <Col xs={4}><FormLabel>Nerve Role:</FormLabel></Col>
                                        <Col xs={8}>
                                            <FormSelect className="d-inline-block" defaultValue={userDetails.Role} onChange={(e) => setTempUserDetails(oS => { return { ...oS, Role: parseInt(e.target.value) }; })} isValid={hasChanged('Role')} title={hasChanged('Role') ? pendingChangesTitle : null}>
                                                <option value={1}>Admin</option>
                                                <option value={2}>Committee</option>
                                                <option value={3}>Presenter</option>
                                            </FormSelect>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={12} lg={6}>
                                    <Row>
                                        <Col xs={4}><FormLabel>Joined Nerve On:</FormLabel></Col>
                                        <Col xs={8}><FormControl className="d-inline-block" readOnly disabled value={moment(userDetails?.JoinDate*1000).format('DD/MM/YYYY') || 'loading...'} /></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    {profile.Role !== 3 &&
                        <Accordion className="mb-3">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <h4>Edit History</h4>
                                    <small style={{ marginLeft: 5 }}> 👀 This can only be seen by Committee and Admins. It includes all changes performed by all users</small>
                                </Accordion.Header>
                                <Accordion.Body>
                                    {logs?.length > 0 ? <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                                        {logs.map((log, index) => {
                                            const timestamp = moment(log.Timestamp * 1000).format('YYYY-MM-DD @ HH:mm:ss');
                                            return (
                                                <li key={index} style={{ borderBottom: '1px solid #6666' }}>
                                                    <small style={{ color: '#999' }}>{timestamp}</small>
                                                    <span style={{ marginLeft: 10 }}>
                                                        <span style={{ color: 'crimson' }}>{users.find(u => u.UserID === log.UserIDActor).Name}</span>: {log.Message}
                                                    </span>
                                                </li>
                                            );
                                        })}
                                    </ul> : logs !== null ? <p>No logs yet.</p> : <p>Loading...</p>}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    }
                </>
            }
        </Container>
    );
}