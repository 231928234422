import { configureStore } from '@reduxjs/toolkit';
import usersSlice from './reducers/usersSlice';
import profileSlice from './reducers/profileSlice';
import rolesSlice from './reducers/rolesSlice';
import showsSlice from './reducers/showsSlice';
import scheduleSlice from './reducers/scheduleSlice';

export default configureStore({
    reducer: {
        users: usersSlice,
        profile: profileSlice,
        roles: rolesSlice,
        shows: showsSlice,
        schedule: scheduleSlice
    },
});