import Login from './login';
import Home from './home';
import Profile from './profile';
import UsersList from './usersList';
import User from './user';
import Schedule from './schedule';
import NewScheduleSlot from './newScheduleSlot';
import EditScheduleSlot from './editScheduleSlot';
import ShowsList from './showsList';
import Show from './show';

const SCREENS = {
    Login,
    Home, Profile,
    UsersList, User, Schedule, NewScheduleSlot, EditScheduleSlot, ShowsList, Show
};

export default SCREENS;