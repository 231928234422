import moment from 'moment';
import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import './home.css';

export default function Home(){
    useEffect(() => {
        document.title = process.env.REACT_APP_PAGE_TITLE.slice(0,-3);
    }, []);

    return (
        <Container className="my-4">
            <h1>Welcome to the Nerve Manager!</h1>
            <hr></hr>
            <h3>This is the site that all current presenters can use to change their show information (e.g. description), and listen/download any of the recordings for their shows!</h3>
            <br></br>
            <h3>To get started, use the navigation menu above (clicking the ☰ on mobile)</h3>
            <br></br>
            <h3>If you need any assistance, please use one of the WhatsApp group chats to contact a committee member.</h3>
            <Container className="footerCredits">
                <p>
                    <small title={'(Built on '+moment(process.env.REACT_APP_VERSION.split('-')[1]*1000).format('DD/MM/YYYY HH:mm:ss')+')'}>
                        Nerve Manager v{process.env.REACT_APP_VERSION.split('-')[0]+(process.env.NODE_ENV === 'development' ? '-dev' : '')}
                    </small> | <small>
                        Developed by Andrew Carter (2024-Present) & <a href="https://tamirslo.dev" target={'_blank'} rel="noreferrer"> Tamir Slobodskoy</a> (2021-2023) &copy; 2021 - {moment().format('YYYY')}
                    </small>
                </p>
            </Container>
        </Container>
    );
}