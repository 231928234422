import moment from 'moment';
import React, { useEffect } from 'react';
import { Accordion, Container, Table } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import './schedule.css';
import { useSelector } from 'react-redux';

export default function Schedule(){
    const schedule = useSelector(state => state.schedule.schedule);
    const shows = useSelector(state => state.shows.shows);

    useEffect(() => {
        document.title = process.env.REACT_APP_PAGE_TITLE+'Schedule';
    }, []);

    let missingSlots = [];
    let overlappingSlots = [];
    let filledSchedule = {};
    const daysArray = new Array(7).fill().map((_, i) => i+1);
    const hoursArray = new Array(24).fill().map((_, i) => moment({hour: i}).format('HH:mm'));
    if(schedule !== null){
        daysArray.forEach(d => {
            missingSlots.push({day: d, hours: hoursArray});
        });
        hoursArray.forEach(hour => {
            filledSchedule[hour] = {};
            daysArray.forEach(d => {
                filledSchedule[hour][d] = null;
            });
        });
        schedule.forEach(slot => {
            const startHour = slot.TimeStart.split(':')[0];
            const endHour = slot.TimeEnd.split(':')[0];
            const sStart = moment(0).utc().hour(parseInt(startHour));
            const sEnd = moment(0).utc().hour(parseInt(endHour));

            const duration = moment(sEnd).subtract(sStart).unix()/60/60;
            for (let i = 0; i < duration; i++) {
                let tempTime = moment(slot.TimeStart, 'HH:mm').add({hour: i}).format('HH:mm');
                if(filledSchedule[tempTime][slot.Day] !== null){
                    if(overlappingSlots.filter(s => s.ScheduleID === slot.ScheduleID).length !== 0) continue;
                    overlappingSlots.push({...slot, overlapWith: filledSchedule[tempTime][slot.Day].id});
                    continue;
                }
                const missingDayIndex = missingSlots.findIndex(d => d.day === parseInt(slot.Day));
                missingSlots[missingDayIndex].hours = missingSlots[missingDayIndex].hours.filter(h => h !== tempTime);
                filledSchedule[tempTime][slot.Day] = {id: slot.ScheduleID, ShowID: slot.ShowID, duration: duration-i};
            }
        });
        var spanningDay = {1:false,2:false,3:false,4:false,5:false,6:false,7:false};
    }

    return (
        <Container className="my-4" fluid>
            <h1>Schedule</h1>
            <hr></hr>
            {(schedule === null) &&
                <p>Retrieving the schedule &amp; shows...</p>
            }
            {schedule !== null && shows.length >= 0 &&
                <>
                    {overlappingSlots.length !== 0 &&
                        <Accordion className="mb-3">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className="warning-alert">
                                    <h2>Warning! <p className="mb-0">The Schedule has some overlapping slots at the following times:</p></h2>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <ol>
                                        {overlappingSlots.map((sOverlapping, sI) => {
                                            const day = moment().isoWeekday(parseInt(sOverlapping.Day)).format('dddd');
                                            const overlappingShow = shows.find(s => s.ShowID === sOverlapping.ShowID);
                                            if(!overlappingShow) return (<li key={sI}>Loading...</li>);
                                            return (
                                                <li key={sI}>
                                                    <Link to={'/shows/schedule/'+sOverlapping.ScheduleID}>{day}: {overlappingShow.Name} @ {sOverlapping.TimeStart}-{sOverlapping.TimeEnd}</Link>
                                                </li>
                                            );
                                        })}
                                    </ol>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    }
                    {missingSlots.flatMap(ms => ms.hours).length !== 0 &&
                        <Accordion className="mb-3">
                            <Accordion.Item eventKey="0" className="danger-alert">
                                <Accordion.Header>
                                    <h2>Warning! <p className="mb-0">The schedule is missing shows from the following times:</p></h2>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <ol>
                                        {missingSlots.map(dMissing => {
                                            const day = moment().isoWeekday(dMissing.day).format('dddd');
                                            return dMissing.hours.map((hMissing, i2) => {
                                                const hour = moment({ hour: hMissing }).format('HH:mm');
                                                return (
                                                    <li key={i2}>{day}: {hour}</li>
                                                );
                                            });
                                        })}
                                    </ol>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    }
                    <Table responsive bordered className="text-center" id="scheduleTable">
                        <thead>
                            <tr>
                                <th></th>
                                {daysArray.map((d, dI) => {
                                    return (<th key={dI}>{moment().isoWeekday(d).format('dddd')}</th>);
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(filledSchedule).map(hour => {
                                const daySlots = filledSchedule[hour];
                                return (
                                    <tr key={hour}>
                                        <th>{hour}</th>
                                        {Object.keys(daySlots).map(day => {
                                            const currentShow = daySlots[day];
                                            if(spanningDay[day] && currentShow.duration === 1){
                                                spanningDay[day] = false;
                                                return null;
                                            }else if(spanningDay[day]) return null;
                                            if(currentShow !== null){
                                                if(currentShow.duration > 1){
                                                    spanningDay[day] = true;
                                                }
                                            }

                                            let overlapping = false;
                                            if(overlappingSlots.filter(s => s.overlapWith === currentShow?.id).length > 0) overlapping = true;
                                            
                                            return (
                                                <ScheduledShow
                                                    key={day}
                                                    id={currentShow?.id}
                                                    empty={currentShow === null}
                                                    showID={currentShow?.ShowID}
                                                    span={currentShow?.duration}
                                                    shows={shows}
                                                    day={day}
                                                    hour={hour}
                                                    slot={schedule.find(s => s.ScheduleID === currentShow?.id)}
                                                    overlapping={overlapping}
                                                />
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </>
            }
        </Container>
    );
}

function ScheduledShow({ id, empty, day, hour, slot, overlapping, shows, showID, span }){
    const navigate = useNavigate();
    if(empty) return (
        <td
            className="slot-empty"
            onClick={() => {
                navigate('/shows/schedule/'+day+'/'+hour);
            }}
        >
            <span>No Show</span>
        </td>
    );
    const show = shows.find(s => s.ShowID === showID);
    return (
        <td
            className={(overlapping && 'slot-overlap') || null}
            title={(overlapping && 'More than one show is scheduled at this hour') || show?.Name+' on '+moment().isoWeekday(parseInt(day)).format('dddd')+' @ '+hour+'-'+slot?.TimeEnd}
            onClick={() => {
                navigate('/shows/schedule/'+id);
            }}
            rowSpan={span}
            style={{verticalAlign: 'middle'}}
        >
            <span>{show?.Name}</span>
        </td>
    );
}