import React from 'react';
import { useSelector } from 'react-redux';

export default function UserName({ uid }) {
    const profile = useSelector(state => state.profile.profile);
    const users = useSelector(state => state.users.users);
    
    let user = null;
    if (profile.UserID === uid) {
        user = profile;
    } else {
        user = users.find(u => u.UserID === uid);
    }

    return (
        <span title={user?.Email}>{user?.Name || 'loading...'}</span>
    );
}