import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './components/navbar';
import SCREENS from './screens';
import './App.css';
import { Container } from 'react-bootstrap';
import NerveAPI from './utils/nerveApi';
import { Toaster } from 'react-hot-toast';

import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { loginRequest } from './msal.config';
import moment from 'moment';
import RxWrapper from './utils/rxWrapper';
import { Provider } from 'react-redux';
import store from './utils/rxStore';

const api = new NerveAPI();

function App({ instance }) {
    const [isLoading, setLoading] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                if (instance.getActiveAccount() !== null) {
                    const response = await instance.acquireTokenSilent({ ...loginRequest, account: instance.getActiveAccount() });
                    api.TOKEN = response.idToken;
                    const authorized = await api.auth();
                    setIsAuthorized(authorized);
                }
            } catch (error) {
                if (error.errorCode === 'interaction_required' || error.errorCode === 'login_required') {
                    const response = await instance.acquireTokenPopup({ ...loginRequest, account: instance.getActiveAccount() }).catch(console.error);
                    api.TOKEN = response.idToken;
                    const authorized = await api.auth();
                    setIsAuthorized(authorized);
                    return;
                } else {
                    console.warn('Unknown error code: ' + error.errorCode);
                    console.error(error);
                    alert('An unknown error has occurred whilst loading. (code: ' + error.errorCode + ', see full error in console)');
                    setIsAuthorized(false);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    if (isLoading) return (
        <Container className="my-auto">
            <h1>Loading...</h1>
        </Container>
    );
    return (
        <MsalProvider instance={instance}>
            <Router>
                <Toaster
                    position={'bottom-right'}
                    reverseOrder={true}
                    toastOptions={{
                        style: { fontSize: 20, fontWeight: '600' }
                    }}
                />
                <UnauthenticatedTemplate>
                    <SCREENS.Login />
                </UnauthenticatedTemplate>
                <AuthenticatedTemplate>
                    {isAuthorized &&
                        <Provider store={store}>
                            <RxWrapper api={api}>
                                {process.env.NODE_ENV === 'development' &&
                                    <div style={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: 'yellow' }}>In development mode, version {process.env.REACT_APP_VERSION.split('-')[0]}-dev (Built on {moment(process.env.REACT_APP_VERSION.split('-')[1] * 1000).format('DD-MM-YYYY HH:mm:ss')})</div>
                                }
                                <NavBar api={api} />
                                <Routes>
                                    <Route path="/" element={<SCREENS.Home />} />
                                    <Route path="/profile" element={<SCREENS.Profile api={api} />} />
                                    <Route path="/users/*" element={
                                        <Routes>
                                            <Route path="/list" element={<SCREENS.UsersList api={api} />} />
                                            <Route path="/:id" element={<SCREENS.User api={api} />} />
                                        </Routes>
                                    } />
                                    <Route path="/shows/*" element={
                                        <Routes>
                                            <Route path="/schedule/*" element={
                                                <Routes>
                                                    <Route path="/" element={<SCREENS.Schedule api={api} />} />
                                                    <Route path="/:id" element={<SCREENS.EditScheduleSlot api={api} />} />
                                                    <Route path="/:day/:hour" element={<SCREENS.NewScheduleSlot api={api} />} />
                                                </Routes>
                                            } />
                                            <Route path="/list" element={<SCREENS.ShowsList api={api} />} />
                                            <Route path="/:id" element={<SCREENS.Show api={api} />} />
                                        </Routes>
                                    } />
                                    
                                </Routes>
                            </RxWrapper>
                        </Provider>
                    }
                    {isAuthorized !== true &&
                        <>
                            <Container>
                                <h1>{isAuthorized === null ? 'Loading user data...' : 'Not Authorized'}</h1>
                                {isAuthorized === false &&
                                    <>
                                        <p>This account is not authorized to use the Nerve Manager website. This may happen for several reasons:</p>
                                        <ol>
                                            <li>You have not signed up for a show.</li>
                                            <li>Your show has not yet been approved by the Nerve Committee.</li>
                                            <li>Your account may have been disabled/archived.</li>
                                        </ol>
                                        <p>If you believe you should have access to this website, please get in touch with your relevant Nerve Committee member.</p>
                                        <button className="btn btn-dark" onClick={() => { instance.logoutPopup(); }}>Sign Out</button>
                                    </>
                                }
                            </Container>
                        </>
                    }
                </AuthenticatedTemplate>
            </Router>
        </MsalProvider>
    );
}

export default App;
